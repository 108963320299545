// theme
$accent: #17a2b8;
$accentDark: #1890a7;
$accentLight: #17a2b860;

$warning: red;
$success: green;

$fas: 'Font Awesome 5 Free';

$disabledLight: #cccccc60;
$disabled: #ccc;
