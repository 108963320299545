@import './_variables.scss';

.loading {
  color: $accentDark;
}

.link {
  cursor: pointer;
  margin: 0 8px;
}

.form-control.ng-touched.ng-invalid {
  border: solid 1px $warning;
  color: $warning;
}

.btn {
  margin: 8px 4px;
}

.card-tools {
  .nav-link {
    cursor: pointer;
    display: inline;
  }
}

.item-id {
  padding: 0 16px;
  font-weight: 200;
}

